/* You can add global styles to this file, and also import other style files */
$primary: var(--cl-textprimary);
$secondary: var(--cl-textsecondary);

// @use "@angular/material" as mat;

// @include mat.core();

// $my-primary: mat.define-palette(mat.$indigo-palette, 500);
// $my-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// $my-theme: mat.define-light-theme(
//     (
//         color: (
//             primary: $my-primary,
//             accent: $my-accent,
//         ),
//     )
// );

// @include mat.core-theme($my-theme);

// @include mat.button-theme($my-theme);

.material-icons {
    font-family: "Material Icons";
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-smoothing: antialiased;
}

a {
    text-decoration: none;
    color: var(--cl-texta);
    cursor: pointer;
}

a.dynamica:hover {
    transform: translatey(1.5px);
    transition: all 0.2s;
    color: var(--cl-textahover);
}

html {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    -webkit-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    height: max-content;
}

*,
:after,
:before {
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
}

body {
    height: max-content;
    width: 100%;
    padding: 0;
    color: $primary;
    background-color: var(--cl-bgcolor);

    font-family: var(--cl-fontfamily);
    font-size: var(--cl-fontsize);
    font-weight: var(--cl-fontweight);
    letter-spacing: var(--cl-letterspacing);
    line-height: var(--cl-lineheight);
    margin: var(--cl-margin);
}

.cdk-overlay-container {
    z-index: 10000;
}

.model-open {
    overflow: hidden;
}

.model-open-mobile {
    overflow: hidden;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.flex-wrap {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    background-color: blueviolet;
    width: 100%;
    margin: 0;
    border: 1px solid red;
}

.flex-div-1 {
    min-height: 200px;
    height: auto;
    display: flex;
    align-self: center;
    background-color: violet;
    width: 380px;
    min-width: 360px;
    margin: 0.5rem;
    border: 1px solid yellowgreen;
}

button {
    border: none;
    background-color: unset;
    width: unset;
    height: unset;
    color: unset;
    text-align: unset;
    padding: 0;
    margin: 0;
    font-style: unset;
    font-variant-ligatures: unset;
    font-variant-caps: unset;
    font-variant-numeric: unset;
    font-variant-east-asian: unset;
    font-weight: unset;
    font-stretch: unset;
    font-size: unset;
    font-family: unset;
    text-rendering: unset;
    color: unset;
    letter-spacing: unset;
    word-spacing: unset;
    line-height: unset;
    text-transform: unset;
    text-indent: unset;
    text-shadow: unset;
    text-align: unset;
    align-items: unset;
    display: flex;
    cursor: pointer;
}

.hotelcard,
.offercard,
.offerheadercard,
.topregioncard,
.wide_regioncard,
.pricecalendar,
.matrixcard {
    position: relative;
    max-width: 1520px;
    border-radius: 0.25rem;
}

.regioncard {
    margin: 10px;
}

.center {
    text-align: center;
    align-items: center;
}

.panel-override {
    max-height: 50vh;
    margin-top: 35px;
    margin-bottom: 30px;
}

.topregioncard {
    border-bottom: 1px solid #ddd;
}

.sortby-card {
    position: relative;
    padding: 0;
    width: 100%;
    color: $primary;
    max-width: 1555px;
    box-shadow: none;
    background: none;
    border-bottom: 1px solid #e2e2e2;
    border-radius: 0;
}

.matTooltip {
    background: #545454;
    font-size: 12px;
    font-weight: 400;
    white-space: pre-line;
}

.mdc-tooltip__surface {
    overflow: auto;
}

.matTooltipSuccess {
    background: rgb(0, 130, 0);
    font-size: 12px;
    font-weight: 400;
    white-space: pre-line;
}

.disable {
    pointer-events: none;
    opacity: 0.5;
}

.vorteile ul {
    list-style: none;
    margin-top: 0.2rem;
    padding-left: 0.5rem;
    margin-bottom: 0;
}

.vorteile ul li:before {
    content: "✓";
    color: var(--cl-bg-color);
    margin-right: 0.5rem;
}

.cookiePanel {
    background-color: #fff;
    color: #545454;
    height: max-content;
}

.strong {
    // color: #545454;
    margin-top: 1rem;
    margin-bottom: 1rem;
    width: 100%;
    display: block;
}

.avatar {
    vertical-align: middle;
    min-width: 3rem;
    width: 3rem;
    max-width: 3rem;
    min-height: 3rem;
    height: 3rem;
    max-height: 3rem;
    border-radius: 50%;
    margin-right: 10px;
    object-fit: cover;
}

.avatar:hover {
    -ms-transform: scale(1.08);
    -moz-transform: scale(1.08);
    -webkit-transform: scale(1.08);
    -o-transform: scale(1.08);
    transform: scale(1.08);
    cursor: pointer;
}

.content-center {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    overflow: hidden;
    margin-top: 0.5rem;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
}

.footer {
    position: relative;
    display: flex;
    justify-content: center;
    overflow: hidden;
    margin-bottom: 2rem;
    flex-direction: column;
}

// h1,
// h2,
// h3,
// h4,
// h5 {
//     margin: 0;
//     line-height: unset;
// }

// h1 {
//     font-size: 2rem;
// }

p {
    padding: 0;
    margin: 0;
}

input::placeholder {
    color: var(--cl-placeholdercolor);
}

input:focus {
    outline: none;
}

.big-icon {
    transform: scale(1.2) translateY(0.12rem);
}

.big-avatar {
    vertical-align: middle;
    min-width: 6rem;
    width: 6rem;
    max-width: 6rem;
    min-height: 6rem;
    height: 6rem;
    max-height: 6rem;
    border-radius: 50%;
    object-fit: cover;
    object-fit: cover;
}

.chip-icon {
    font-size: 1rem;
    // transform: scale(1.2) translateY(0.3rem);
}

.centered-icon {
    font-size: 18px;
    transform: translateY(0.25em);
}

.imgCard {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 0.6rem;
    overflow: hidden;
}

.imgCardImage {
    height: 100%;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
}

.container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

// Overlay designe

.noselect {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
}

.overlay-serachres-list {
    display: flex;
    width: 100%;
    color: #0d479f;
    flex-direction: column;
}

.overlay-serachres-list-header {
    background-color: #d8e2f2;
    display: flex;
    width: 100%;
    height: 24px;
    overflow: hidden;
}

.overlay-serachres-list-content {
    display: flex;
    flex-direction: column;
    padding: 10px;
    background-color: #fff;
    height: 3rem;
}
.overlay-serachres-list-content > span {
    font-weight: 500;
}

.overlay-serachres-list-content > small {
    font-weight: 100;
}

// Overlay designe end

.clicable {
    cursor: pointer;
}

.alert-primary {
    color: #084298;
    background-color: #cfe2ff;
    border-color: #b6d4fe;
    margin-top: 1rem;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    border-radius: 0.25rem;
}

.alert-primary span {
    margin-left: 0.5rem;
}

.alert-danger {
    color: #ca430e;
    background-color: #ffdfcc;
    border-color: #ffd4b8;
    margin: 0.5rem;
    padding: 0.5rem;
}

.flex-between {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

// traffics icons

@font-face {
    font-family: "icomoon";
    src: url("./assets/traffics/fonts/icomoon.eot?qkrysw");
    src:
        url("./assets/traffics/fonts/icomoon.eot?qkrysw#iefix") format("embedded-opentype"),
        url("./assets/traffics/fonts/icomoon.ttf?qkrysw") format("truetype"),
        url("./assets/traffics/fonts/icomoon.woff?qkrysw") format("woff"),
        url("./assets/traffics/fonts/icomoon.svg?qkrysw#icomoon") format("svg");
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"],
[class*=" icon-"] {
    /* use to prevent issues with browser extensions that change fonts */
    font-family: "icomoon";
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-animation:before {
    content: "\e900";
}
.icon-babysitter:before {
    content: "\e901";
}
.icon-beach_location_close:before {
    content: "\e902";
}
.icon-beach_location_far:before {
    content: "\e903";
}
.icon-child_care:before {
    content: "\e904";
}
.icon-child_cot:before {
    content: "\e905";
}
.icon-child_friendly:before {
    content: "\e906";
}
.icon-child_menu:before {
    content: "\e907";
}
.icon-child_miniclub:before {
    content: "\e908";
}
.icon-child_playground:before {
    content: "\e909";
}
.icon-child_programm:before {
    content: "\e90a";
}
.icon-child_room:before {
    content: "\e90b";
}
.icon-child_seat:before {
    content: "\e90c";
}
.icon-club_hotel:before {
    content: "\e90d";
}
.icon-diet:before {
    content: "\e90e";
}
.icon-disabled:before {
    content: "\e90f";
}
.icon-internet_access:before {
    content: "\e910";
}
.icon-location_cental:before {
    content: "\e911";
}
.icon-minidisco:before {
    content: "\e912";
}
.icon-pool_child:before {
    content: "\e913";
}
.icon-pool_indoor:before {
    content: "\e914";
}
.icon-pool_outdoor:before {
    content: "\e915";
}
.icon-room_internet:before {
    content: "\e916";
}
.icon-seaview:before {
    content: "\e917";
}
.icon-separate_bedrooms:before {
    content: "\e918";
}
.icon-smoking_room:before {
    content: "\e919";
}
.icon-sport:before {
    content: "\e91a";
}
.icon-sport_aerobic:before {
    content: "\e91b";
}
.icon-sport_ball:before {
    content: "\e91c";
}
.icon-sport_biking:before {
    content: "\e91d";
}
.icon-sport_diving:before {
    content: "\e91e";
}
.icon-sport_fitness:before {
    content: "\e91f";
}
.icon-sport_golf:before {
    content: "\e920";
}
.icon-sport_hike:before {
    content: "\e921";
}
.icon-sport_other:before {
    content: "\e922";
}
.icon-sport_riding:before {
    content: "\e923";
}
.icon-sport_room:before {
    content: "\e924";
}
.icon-sport_tennis:before {
    content: "\e925";
}
.icon-theme_bus:before {
    content: "\e926";
}
.icon-theme_camping:before {
    content: "\e927";
}
.icon-theme_city:before {
    content: "\e928";
}
.icon-theme_cruise:before {
    content: "\e929";
}
.icon-theme_cure:before {
    content: "\e92a";
}
.icon-theme_fewo:before {
    content: "\e92b";
}
.icon-theme_rail:before {
    content: "\e92c";
}
.icon-theme_roundtrip:before {
    content: "\e92d";
}
.icon-theme_ski:before {
    content: "\e92e";
}
.icon-watersport:before {
    content: "\e92f";
}
.icon-wellness:before {
    content: "\e930";
}
.icon-wellness_aktiv:before {
    content: "\e931";
}
.icon-wellness_akupunktur:before {
    content: "\e932";
}
.icon-wellness_antiaging:before {
    content: "\e933";
}
.icon-wellness_ayurveda:before {
    content: "\e934";
}
.icon-wellness_beautyfarm:before {
    content: "\e935";
}
.icon-wellness_cure:before {
    content: "\e936";
}
.icon-wellness_diet:before {
    content: "\e937";
}
.icon-wellness_massage:before {
    content: "\e938";
}
.icon-wellness_sauna:before {
    content: "\e939";
}
.icon-wellness_thalasso:before {
    content: "\e93a";
}
.icon-wlan:before {
    content: "\e93b";
}

.customModal {
    padding: 0;
}

.customModal .mdc-dialog__surface {
    padding: 0;
    overflow: hidden;
}

.customModalimg {
    padding: 0;
    background-color: rgba(0, 0, 0, 0.49);
    color: #fff;
    overflow: hidden;
}

.customModalimg .mdc-dialog__surface {
    padding: 0;
    background-color: transparent;
    color: #fff;
    overflow: hidden;
}

.customscroll::-webkit-scrollbar {
    width: 9px; /* width of the entire scrollbar */
}

.customscroll::-webkit-scrollbar-track {
    background: #d7d7d7;
}

.customscroll::-webkit-scrollbar-thumb {
    background-color: #bdbdbd;
}

.full-screen-modal {
    max-width: 100% !important;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.49);
    color: #fff;
    .mdc-dialog__surface {
        background-color: transparent;
        overflow: hidden;
    }
    .mat-mdc-dialog-container {
        max-width: 100vw;
        max-height: 100vh;
        padding: 0;
        height: 100%;
        width: 100%;
        background-color: transparent;
        color: #fff;
        .mat-dialog-content {
            max-height: unset;
            background-color: transparent;
            color: #fff;
        }
    }
}

// table section

.table td,
.table th {
    padding: 0.2rem;
    vertical-align: top;
    font-size: 14px;
    border-right: 1px dotted #d2cece;
}

table {
    border-bottom: none;
    border-collapse: separate;
    border-spacing: 0;
}

.table thead th {
    border-bottom: none;
}

.success {
    background-color: #c8e6c9;
    color: #000;
}

.error {
    background-color: #ffcdd2;
    color: #000;
}

.mat-form-field {
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: normal;
}

/* The loading Class */

@keyframes aniHorizontal {
    0% {
        background-position: -100% 0;
    }

    100% {
        background-position: 100% 0;
    }
}

.skeleton {
    position: relative;
}

.skeleton:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    animation-name: aniHorizontal;
    animation-duration: 3s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    background: linear-gradient(to right, #e2e2e2 2%, #e2e2e2d4 18%, #e2e2e2 33%);
    background-size: 50%;
}

// passolution content
.col-md-6 {
    flex-direction: column;
    margin-bottom: 1.5rem;
}

.col-lg-4 {
    width: 30%;
    margin: 0.3rem;
    padding: 0.5rem;
    background: #d1d1d1;
    border-radius: 0.5rem;
}

.row {
    display: flex;
    flex-wrap: wrap;
    margin-top: 1rem;
}

.row .heading {
    font-size: 1.2rem;
    padding: 0 0 0.25rem;
    border-bottom: none;
    color: #3973b9;
}
.condition_types {
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 5px;
}

.condition_headline {
    font-size: 1.4rem;
    margin-bottom: 10px;
}

@media only screen and (min-width: 601px) and (max-width: 1260px) {
    .col-lg-4 {
        width: 100%;
    }
}
@media only screen and (max-width: 600px) {
    .col-lg-4 {
        width: 100%;
    }

    table,
    tbody {
        width: 100%;
    }

    tbody td {
        word-break: break-all;
    }
}

.favbtn {
    background-color: #fff;
    color: #545454;
    width: 30px;
    height: 30px;
    margin-right: 0.3rem;
    box-shadow: none;
}

.leaflet-popup-content {
    margin: 5px 5px !important;
    width: max-content !important;
}

.marker-cluster-small {
    background-color: rgba(45, 49, 55, 0.6) !important;
}
.marker-cluster-small div {
    background-color: rgba(45, 49, 55, 0.6) !important;
}

.marker-cluster-medium {
    background-color: rgba(45, 49, 55, 0.6) !important;
}
.marker-cluster-medium div {
    background-color: rgba(45, 49, 55, 0.6) !important;
}

.marker-cluster-large {
    background-color: rgba(45, 49, 55, 0.6) !important;
}
.marker-cluster-large div {
    background-color: rgba(45, 49, 55, 0.6) !important;
}

/* IE 6-8 fallback colors */
.leaflet-oldie .marker-cluster-small {
    background-color: rgb(45, 49, 55) !important;
}
.leaflet-oldie .marker-cluster-small div {
    background-color: rgb(45, 49, 55) !important;
}

.leaflet-oldie .marker-cluster-medium {
    background-color: rgb(45, 49, 55) !important;
}
.leaflet-oldie .marker-cluster-medium div {
    background-color: rgb(45, 49, 55) !important;
}

.leaflet-oldie .marker-cluster-large {
    background-color: rgb(45, 49, 55) !important;
}
.leaflet-oldie .marker-cluster-large div {
    background-color: rgb(45, 49, 55) !important;
}

.marker-cluster {
    background-clip: padding-box;
    border-radius: 20px;
}
.marker-cluster div {
    width: 30px;
    height: 30px;
    margin-left: 5px;
    margin-top: 5px;

    text-align: center;
    border-radius: 15px;
}
.marker-cluster span {
    line-height: 30px;
}

.grecaptcha-badge {
    visibility: hidden;
}

.newsletterhideforonlineversion {
    display: none;
}

textarea::placeholder {
    font: inherit;
}

textarea::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    font: inherit;
}

textarea:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    font: inherit;
}

textarea::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    font: inherit;
}

textarea:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    font: inherit;
}

textarea:placeholder-shown {
    /* Standard Pseudo-class */
    font: inherit;
}

input::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    font: inherit;
}
input:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    font: inherit;
}
input::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    font: inherit;
}
input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    font: inherit;
}
input:placeholder-shown {
    /* Standard Pseudo-class */
    font: inherit;
}

div.clevertextcontent span {
    display: inline-block;
    max-width: 100%;
}

.mat-mdc-form-field {
    width: 100%;
}

mark {
    color: var(--cl-bg-color);
    background: transparent;
}

.bbotwrapper {
    display: flex;
    height: max-content;
    border: 1px solid rgb(16, 93, 224);
    border-radius: 0.3rem;
    font-size: 0.8rem;
}

.hcheck {
    display: flex;
    align-items: center;
    justify-content: center;
}

.pursico {
    font-size: 1rem;
    width: unset;
    height: unset;
}

.purswr {
    display: flex;
    align-items: center;
    background-color: rgb(16, 93, 224);
    color: #fff;
    width: max-content;
    padding: 0.2rem 0.3rem;
    border-top-left-radius: 0.2rem;
    border-bottom-left-radius: 0.2rem;
}

:root {
    --rtype-bgcolor: rgb(183, 224, 237);
    --rtype-color: inherit;
    --rtype-selected-bgcolor: rgb(231 240 242);
    --rtype-selected-color: inherit;

    --clever-countdown-color: black;
    --clever-countdown-text-color: white;
    --clever-countdown-font-size: 4.5rem;

    --clever-countdown-font-size-mobile: 2.5rem;
    --clever-countdown-color-mobile: black;
    --clever-countdown-text-color-mobile: white;
}

.leaflet-tooltip.my-labels {
    background-color: #fff;
    font-weight: bold;
    font-size: 16px;
}
